const investments = [
  {
    city: "Kielce",
    url: "/mieszkania/kielce/",
    apartments: 220 + 147 + 124,
    area_min: 25,
    area_max: 91,
    investments: [
      {
        url: "/mieszkania/kielce/krakowska-rogatka/",
        city: "Kielce",
        logo: require("assets/images/logo-krakowska-rogatka.svg").default,
        image: require("assets/images/investments/kr-tile-image.jpg").default,
        title: "Krakowska Rogatka",
        address_preffix: "ul.",
        address: "Miodowicza 1",
        areas: "od 25 do 79",
        area_min: 25,
        area_max: 79,
        apartments: "220",
        status: "W sprzedaży",
        deadline: "II kwartał",
        deadline_year: "2026",
        investment: "krakowska_rogatka",
        position: {
          lat: 50.8638875,
          lng: 20.6287816,
        },
      },
      {
        url: "/mieszkania/kielce/rezydencja-sikorskiego/",
        city: "Kielce",
        logo: require("assets/images/logo-rezydencja-sikorskiego.svg").default,
        image: require("assets/images/investments/rs-tile-image.jpg").default,
        title: "Rezydencja Sikorskiego",
        address_preffix: "ul.",
        address: "Sikorskiego",
        // address_second: "ul. Sikorskiego 43  (budynki C i D)",
        areas: "od 25 do 92",
        area_min: 25,
        area_max: 92,
        apartments: "147 (etap I + II)",
        status: "W sprzedaży",
        deadline: "II/III kwartał",
        deadline_year: "2025",
        investment: "rezydencja_sikorskiego",
        position: {
          lat: 50.898213,
          lng: 20.671376,
        },
      },
      {
        url: "/mieszkania/kielce/sky-trust/",
        city: "Kielce",
        logo: require("assets/images/logo-sky-trust.svg").default,
        image: require("assets/images/investments/st-tile-image.jpg").default,
        title: "Sky Trust",
        address_preffix: "al.",
        address: "Solidarności",
        areas: "od 34 do 80",
        area_min: 34,
        area_max: 80,
        apartments: "124",
        status: "W sprzedaży",
        deadline: "2025",
        investment: "sky_trust",
        position: {
          lat: 50.880743,
          lng: 20.652638,
        },
      },
      // {
      //   // url: "/mieszkania/kielce/nove-slichowice/",
      //   city: "Kielce",
      //   logo: require("assets/images/logo-nove-slichowice.svg").default,
      //   image: require("assets/images/investments/ns-tile-image.jpg").default,
      //   title: "Nove Ślichowice",
      //   address_preffix: "ul.",
      //   address: "Piekoszowska 191 C",
      //   areas: "od 29 do 70",
      //   area_min: 29,
      //   area_max: 70,
      //   apartments: "59",
      //   status: "Sprzedaż zakończona",
      //   deadline: "I kwartał",
      //   deadline_year: "2024",
      //   investment: "nove_slichowice",
      //   position: {
      //     lat: 50.8823363,
      //     lng: 20.5781127,
      //   },
      // },
    ],
    office: {
      address_preffix: "Al.",
      address: "Solidarności 34 (parking Exbud)",
      city: "25-323 Kielce",
      hours: "pn-pt: 10:00 - 18:00",
      email: "kielce@trustinvestment.pl",
      map: {
        lat: 50.881796,
        lng: 20.6534564,
      },
    },
  },
  {
    city: "Częstochowa",
    url: "/mieszkania/czestochowa/",
    apartments: 106 + 64,
    area_min: 25,
    area_max: 69,
    investments: [
      {
        url: "/mieszkania/czestochowa/plaza-city/",
        city: "Częstochowa",
        logo: require("assets/images/logo-plaza-city-horizontal.svg").default,
        image: require("assets/images/investments/pc-tile-image.jpg").default,
        title: "Plaza City",
        address_preffix: "ul.",
        address: "Łódzka/Wawrzynowicza",
        areas: "od 26 do 69",
        area_min: 26,
        area_max: 69,
        apartments: "106",
        status: "W sprzedaży",
        deadline: "IV kwartał",
        deadline_year: "2025",
        investment: "plaza_city",
        position: {
          lat: 50.82988656043992,
          lng: 19.099622009869556,
        },
      },
      {
        // url: "/mieszkania/czestochowa/stacja-kopernik/",
        city: "Częstochowa",
        logo: require("assets/images/logo-stacja-kopernik.svg").default,
        image: require("assets/images/investments/sk-tile-image.jpg").default,
        title: "Stacja Kopernik",
        address_preffix: "ul.",
        address: "Kopernika 1",
        areas: "od 25 do 67",
        area_min: 25,
        area_max: 67,
        apartments: "64",
        locales: "7",
        status: "Sprzedaż zakończona",
        deadline: "IV kwartał",
        deadline_year: "2024",
        investment: "stacja_kopernik",
        position: {
          lat: 50.8081078,
          lng: 19.1172618,
        },
      },
    ],
    office: {
      address_preffix: "ul.",
      address: "Łódzka 52 (parking)",
      city: "42-217 Częstochowa",
      hours: "pn-pt: 10:00 - 18:00",
      email: "czestochowa@trustinvestment.pl",
      phone: "+48 600 700 063",
      map: {
        lat: 50.82282242488181,
        lng: 19.10096991186518,
      },
    },
  },
  {
    city: "Gliwice",
    url: "/mieszkania/gliwice/",
    apartments: 53,
    area_min: 27,
    area_max: 66,
    investments: [
      {
        url: "/mieszkania/gliwice/rezydencja-centrum/",
        city: "Gliwice",
        logo: require("assets/images/logo-rezydencja-centrum.svg").default,
        image: require("assets/images/investments/rc-tile-image.jpg").default,
        title: "Rezydencja Centrum",
        address_preffix: "ul.",
        address: "Nasyp 2c",
        areas: "od 27 do 66",
        area_min: 27,
        area_max: 66,
        apartments: "53",
        locales: "10",
        status: "W sprzedaży",
        deadline: "II kwartał",
        deadline_year: "2025",
        investment: "rezydencja_centrum",
        position: {
          lat: 50.2970052101548,
          lng: 18.676202200029955,
        },
      },
    ],
    office: {
      address:
        "Parking przy Gliwickim Centrum Handlowym, wejście od ul. Dworcowej",
      city: "44-110 Gliwice",
      hours: "pn-pt: 10:00 - 18:00",
      email: "gliwice@trustinvestment.pl",
      phone: "+48 600 700 603",
      map: {
        lat: 50.297229,
        lng: 18.6753396,
      },
    },
  },
  {
    city: "Radom",
    url: "/mieszkania/radom/",
    // apartments: 133 + 189,
    apartments: 506,
    area_min: 25,
    area_max: 84,
    investments: [
      {
        url: "/mieszkania/radom/ultradom/",
        city: "Radom",
        logo: require("assets/images/logo-ultradom.svg").default,
        image: require("assets/images/investments/ultradom-tile-image.jpg")
          .default,
        title: "Ultradom",
        address_preffix: "ul.",
        address: "Gołębiowska",
        areas: "od 25 do 80",
        area_min: 25,
        area_max: 80,
        apartments: "184",
        status: "W sprzedaży",
        deadline: "II kwartał",
        deadline_year: "2026",
        investment: "ultradom",
        position: {
          lat: 51.4150693,
          lng: 21.1825883,
        },
      },
      {
        url: "/mieszkania/radom/rezydencja-swierkowa/",
        city: "Radom",
        logo: require("assets/images/logo-rezydencja-swierkowa.svg").default,
        image: require("assets/images/investments/rsw-tile-image.jpg").default,
        title: "Rezydencja Świerkowa",
        address_preffix: "ul.",
        address: "Świerkowa 47",
        areas: "od 25 do 74",
        area_min: 25,
        area_max: 74,
        apartments: "133",
        status: "W sprzedaży",
        deadline: "II kwartał",
        deadline_year: "2026",
        investment: "rezydencja_swierkowa",
        position: {
          lat: 51.37915946683257,
          lng: 21.172359085016875,
        },
      },
      {
        url: "/mieszkania/radom/panorama-struga/",
        city: "Radom",
        logo: require("assets/images/logo-panorama-struga.svg").default,
        image: require("assets/images/investments/ps-tile-image.jpg").default,
        title: "Panorama Struga",
        address_preffix: "ul.",
        address: "Struga 60",
        areas: "od 25 do 84",
        area_min: 25,
        area_max: 84,
        apartments: "189",
        status: "W sprzedaży",
        deadline: "IV kwartał",
        deadline_year: "2025",
        investment: "panorama_struga",
        position: {
          lat: 51.4078185,
          lng: 21.1716883,
        },
      },
    ],
    office: {
      address_preffix: "ul.",
      address: "Struga 60",
      city: "26-600 Radom",
      hours: "pn-pt: 10:00 - 18:00",
      email: "radom@trustinvestment.pl",
      phone: "+48 600 700 630",
      map: {
        lat: 51.4080079,
        lng: 21.1714246,
      },
    },
  },
]

export default investments
