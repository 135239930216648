import "./style.scss"

import React from "react"
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api"
import { withPrefix } from "gatsby"

import mapStyle from "utils/mapStyle"

import { useLocale } from "context/locale"

const containerStyle = {
  width: "100%",
  height: "400px",
}

const Office = ({ zoom = 15, office }) => {
  const { t } = useLocale()
  const center = {
    lat: office.map.lat,
    lng: office.map.lng,
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAw_QqzRWAoMaaZZQLLfsZVgeqAnhLeiWc",
  })

  const officeCity = office?.city?.split(" ")

  return (
    <div className="investments-content-office">
      <div className="investments-content-office__wrapper">
        <h5>{t("Biuro sprzedaży")}</h5>
        {office.address && (
          <address>
            {t(office?.address_preffix)} {t(office?.address)}
            {officeCity?.length > 0 && (
              <>
                <br />
                {officeCity[0]} {t(officeCity[1])}
              </>
            )}
          </address>
        )}
        {office.hours && (
          <p>
            <strong>{t("Godziny pracy")}:</strong>
            {t(office?.hours)}
          </p>
        )}
        <a
          href={`mailto:${
            office?.email ? office?.email : "sprzedaz@trustinvestment.pl"
          }`}
        >
          {office?.email ? office?.email : "sprzedaz@trustinvestment.pl"}
        </a>
        <a href={`tel:${office?.phone ? office?.phone : "+48 600 900 500"}`}>
          {office?.phone ? office?.phone : "+48 600 900 500"}
        </a>
      </div>

      <div className="investments-content-office__map">
        {isLoaded ? (
          <GoogleMap
            center={center}
            zoom={zoom}
            options={{ styles: mapStyle }}
            mapContainerStyle={containerStyle}
          >
            <Marker icon={withPrefix("/ico-pointer.svg")} position={center} />
          </GoogleMap>
        ) : null}
      </div>
    </div>
  )
}

export default Office
