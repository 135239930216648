import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import { useApartments } from "context/apartments"
import { useLocale } from "context/locale"

import ArrowTile from "components/Icons/arrow"

const InvestmentTile = ({
  type,
  url,
  city,
  logo,
  image,
  title,
  address_preffix,
  address,
  address_second,
  areas,
  area_min,
  area_max,
  office_areas,
  apartments,
  offices,
  locales,
  status,
  deadline,
  deadline_year,
  cityPortfolio,
  areaPortfolio,
  investment,
}) => {
  const { t } = useLocale()
  const { allApartments } = useApartments()

  const apartmentsAvailable = allApartments.filter(
    item =>
      item?.acfApartment?.investment === investment &&
      item?.acfApartment?.type === "apartment" &&
      item?.acfApartment?.status === "available"
  )

  return (
    <Link
      to={t(url)}
      className={`investment-tile${
        type === "portfolio" ? " investment-tile--portfolio" : ""
      }${!url ? " investment-tile--disabled" : ""}`}
    >
      <div className="apartment-tile__top">
        {logo ? <img src={logo} alt="" /> : <span />}
        {city && <strong>{t(city)}</strong>}
      </div>
      <div className="investment-tile__image">
        <img src={image} alt={title} />
        {status && (
          <span
            className={
              status === "W sprzedaży"
                ? "sell"
                : status === "W przygotowaniu"
                ? "progress"
                : "sold"
            }
          >
            {t(status)}
          </span>
        )}
      </div>
      <h3>{t(title)}</h3>
      {address && (
        <address>
          {t(address_preffix)} {address}
          {address_second && <br />}
          {address_second && `${address_second}`}
        </address>
      )}
      <div
        className={`investment-tile__bottom${
          address_second ? " investment-tile__bottom--additional-address" : ""
        }`}
      >
        <div className="investment-tile__list">
          {!!areas && (
            <p>
              {t("Metraże mieszkań")}:{" "}
              <strong>
                {area_min && `${t("od")} ${area_min}`}
                {area_max && ` ${t("do")} ${area_max}`} m<sup>2</sup>
              </strong>
            </p>
          )}
          {!!office_areas && (
            <p>
              {t("Metraże")}:{" "}
              <strong>
                {area_min && `od ${area_min}`}
                {area_max && `do ${area_max}`} m<sup>2</sup>
              </strong>
            </p>
          )}
          {!!apartments && (
            <p>
              {t("Liczba mieszkań")}
              {investment === "plaza_city" ? " w etapie I" : ""}:{" "}
              <strong>{apartments}</strong>
            </p>
          )}
          {investment !== "rezydencja_centrum" &&
            investment !== "sky_trust_offices" &&
            investment !== "rezydencja_sikorskiego" &&
            investment !== "panorama_struga" &&
            investment !== "rezydencja_swierkowa" &&
            type !== "portfolio" && 
            apartmentsAvailable?.length > 0 && (
              <p>
                {t("Dostępnych mieszkań")}
                {investment === "plaza_city" ? " w etapie I" : ""}:{" "}
                <strong>{apartmentsAvailable?.length}</strong>
              </p>
            )}
          {!!locales && (
            <p>
              {t("Liczba lokali usługowych")}: <strong>{locales}</strong>
            </p>
          )}
          {!!offices && (
            <p>
              {t("Liczba biur i lokali")}: <strong>{offices}</strong>
            </p>
          )}
          {!!status && (
            <p>
              {t("Status inwestycji")}: <strong>{t(status)}</strong>
            </p>
          )}
          {!!deadline && (
            <p>
              {t("Data oddania")}:{" "}
              <strong>
                {t(deadline)} {deadline_year && deadline_year}
              </strong>
            </p>
          )}
          {!!cityPortfolio && (
            <p>
              {t("Miasto")}: <strong>{cityPortfolio}</strong>
            </p>
          )}
          {!!areaPortfolio && (
            <p>
              {t("Powierzchnia")}: <strong>{areaPortfolio}</strong>
            </p>
          )}
        </div>
        {url && <ArrowTile />}
      </div>
    </Link>
  )
}

export default InvestmentTile
